import React from 'react'
import { getSrc } from 'gatsby-plugin-image'
import useSiteDefaults from '../../hooks/useSiteDefaults'
import SchemaOrg from './SchemaOrg'
import { isPost } from './SeoHelpers'

const SEO = ({
    author,
    dateModified,
    description,
    facebookPostImage,
    postType,
    title,
    twitterPostImage,
    url,
    keywords,
    yoastTitle,
    articleBody,
}) => {
    // Pull data from WordPress and Gatsby config
    const { settings, site, social } = useSiteDefaults()
    const wpSettings = settings.allSettings
    const meta = site.siteMetadata

    let tagLine = meta.description
    if (postType === 'post') {
        tagLine = wpSettings.generalSettingsDescription
    }
    const socialFallback = getSrc(social?.childImageSharp) || false

    const pageDescription = description || tagLine

    // Set the title from the browser. If there is a page title, set properly. Otherwise fall back
    let browserTitle = yoastTitle
    if (!browserTitle) {
        if (title) {
            browserTitle = `${title} | ${meta.title}`
        }
        if (!browserTitle) {
            browserTitle = `${meta.title} | ${meta.description}`
        }
    }

    const facebookMetaImage =
        facebookPostImage || twitterPostImage || socialFallback || false

    const twitterMetaImage =
        twitterPostImage || facebookPostImage || socialFallback || false

    const postUrl = url ? `${meta.siteUrl}${url}` : meta.siteUrl

    return (
        <>
            {/* General tags */}
            <title>{browserTitle}</title>
            {pageDescription && (
                <meta name="description" content={pageDescription} />
            )}
            {facebookMetaImage && (
                <meta name="image" content={facebookMetaImage} />
            )}

            {/* OpenGraph tags */}
            <meta property="og:url" content={postUrl} />
            {isPost(postType) ? (
                <meta property="og:type" content="article" />
            ) : null}
            <meta property="og:title" content={browserTitle} />
            {description && (
                <meta property="og:description" content={description} />
            )}
            {facebookMetaImage && (
                <meta
                    property="og:image"
                    content={meta.siteUrl + facebookMetaImage}
                />
            )}
            {meta.facebookAppID && (
                <meta property="fb:app_id" content={meta.facebookAppID} />
            )}
            {dateModified && (
                <meta property="LastModifiedDate" content={dateModified} />
            )}
            {author && (
                <meta name="author" property="Creator" content={author} />
            )}

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={meta.author} />
            <meta name="twitter:title" content={browserTitle} />
            {description && (
                <meta name="twitter:description" content={description} />
            )}
            {twitterMetaImage && (
                <meta
                    name="twitter:image"
                    content={meta.siteUrl + twitterMetaImage}
                />
            )}
            {keywords?.length > 0 && (
                <meta name="keywords" content={keywords.join(`, `)} />
            )}
            <SchemaOrg
                author={author}
                url={url}
                title={browserTitle}
                image={meta.siteUrl + facebookMetaImage}
                description={description}
                dateModified={dateModified}
                siteUrl={meta.siteUrl}
                postType={postType}
                defaultTitle={tagLine}
                articleBody={articleBody}
            />
        </>
    )
}

export default SEO
