import React from 'react'
import './style.scss'

const AdBlock = () => (
    <div className="ad-wrap">
        <script
            async
            type="text/javascript"
            src="//cdn.carbonads.com/carbon.js?serve=CEAIVKJN&placement=robertmarshalldev"
            id="_carbonads_js"
        ></script>
    </div>
)

export default AdBlock
