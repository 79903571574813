import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import AdBlock from '../components/AdBlock'
import ArticleCard from '../components/ArticleCard'
import FilterCategories from '../components/FilterCategories'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO/SEO'
import ArticleContainer from '../containers/ArticleContainer'
import PostsWrap from '../containers/PostsWrap'
import Layout from '../containers/Layout'

const Posts = ({ data, pageContext }) => {
    const {
        allWpPost: { nodes, pageInfo },
    } = data

    const { currentPage } = pageInfo

    const { archivePath } = pageContext

    return (
        <Layout>
            <ArticleContainer>
                <h1>Blog {currentPage > 1 && `- Page ${currentPage}`}</h1>
                {currentPage === 1 && <FilterCategories />}

                <AdBlock />

                <PostsWrap>
                    {nodes.map((node, count) => {
                        const image = getImage(
                            node?.featuredImage?.node?.thumbnail
                        )

                        const imageTitle =
                            node?.featuredImage?.node?.title || ''
                        const imageAlt =
                            node?.featuredImage?.node?.altText || ''

                        return (
                            <ArticleCard
                                key={node.uri}
                                count={count}
                                uri={node.uri}
                                image={image}
                                imageTitle={imageTitle}
                                imageAlt={imageAlt}
                                title={node.title}
                                modifiedForUser={node.modifiedForUser}
                                modifiedForSchema={node.modifiedForSchema}
                                excerpt={node.cleanExcerpt}
                            />
                        )
                    })}
                </PostsWrap>

                <Pagination
                    prefix={archivePath}
                    currentPage={pageInfo.currentPage}
                    pageCount={pageInfo.pageCount}
                />
            </ArticleContainer>
        </Layout>
    )
}

export const query = graphql`
    query Posts($offset: Int!, $perPage: Int!) {
        allWpPost(
            limit: $perPage
            skip: $offset
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                ...PostPreviewContent
            }
            pageInfo {
                currentPage
                pageCount
            }
        }
    }
`

export default Posts

export const Head = ({ data, location }) => {
    const {
        allWpPost: {
            pageInfo: { currentPage },
        },
    } = data

    return (
        <>
            <SEO
                yoastTitle={`Blog - Page ${currentPage} | Discussing Gatsby, React, Jest & Headless WordPress, and More`}
                url={location.pathname}
            />
            {currentPage > 1 && (
                <meta name="robots" content="noindex,nofollow" />
            )}
        </>
    )
}
